import { Flex } from '@gravity-ui/uikit'
import Empty from 'components/Empty'
import loadingElements from 'components/LoadingElements/LoadingElements'
import { TariffsQuery, useTariffsQuery } from 'queries'
import { createElement as $, FC } from 'react'
import TariffIteratee from '../TariffIteratee'
import './style.scss'

const Content: FC<TariffsProps> = ({
  data,
  loading
}) => {
  if (!data) return $(Empty)
  if (loading && !data) return loadingElements
  return $(TariffsList, data)
}

const TariffsList: FC<TariffsQuery> = ({ tariffs }) => {
  return $(Flex, { gap: 2, direction: 'column' },
    tariffs.map(TariffIteratee))
}

export type TariffsProps = ReturnType<typeof useTariffsQuery>

export default Content