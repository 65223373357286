import { Text } from '@gravity-ui/uikit'
import FormControls from 'components/FormControls/FormControls'
import { createElement as $, FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { CulturalEventProps } from '..'
import Attendance from '../Attendance'
import CulturalEventForm from '../CulturalEventForm'
import Images from '../Images'
import './style.scss'

const CulturalEventMobile: FC<CulturalEventProps> = ({ culturalEvent, loading }) =>
  $(Fragment, null,
    $(Fragment, null,
      $(Text, null, 
        $(FormattedMessage, { id: 'culturalEvent.images' })),
      $(Images, culturalEvent)),
    $(CulturalEventForm),
    $(Attendance),
    $(FormControls, { 
      loading, 
      title: 'main.calendar' 
    }))

export default CulturalEventMobile