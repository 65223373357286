import { ArrowLeft, Xmark } from "@gravity-ui/icons"
import { spacing, TextInput } from "@gravity-ui/uikit"
import AdornmentButton from "components/MobileMenu/AdornmentButton"
import { createElement as $, Dispatch, FC, SetStateAction } from "react"
import { useIntl } from "react-intl"
import './style.scss'

const Search: FC<SearchProps> = ({
  search,
  setSearch,
  setSearchVisible
}) => {
  const { formatMessage } = useIntl()
  const back = $(AdornmentButton, { data: ArrowLeft, onClick: () => setSearchVisible(false) })
  const clear = $(AdornmentButton, { onClick: () => setSearch(''), data: Xmark })

  return $(TextInput, {
    autoFocus: true,
    view: 'normal',
    className: `mobile-menu_container ${spacing({ p: 2 })}`,
    size: 'l',
    placeholder: formatMessage({ id: 'alias.search' }),
    value: search,
    onUpdate: (value) => setSearch(value),
    leftContent: back,
    rightContent: search?.length && clear,
    onBlur: () => {
      setSearchVisible(false)
      setSearch('')
    },
  })
}

type SearchProps = {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  setSearchVisible: (arg: boolean) => void
}

export default Search