import { Skeleton } from '@gravity-ui/uikit'
import { CulturalEventFragment, CulturalEventImagesQuery, CulturalEventImagesQueryResult, useCulturalEventImagesQuery } from 'queries'
import { createElement as $, FC, Fragment } from 'react'
import RemoveImageButtonWithConfirmation from './RemoveButton'
import UploadButton from './UploadButton'
import './style.scss'

const Images: FC<CulturalEventFragment> = (culturalEvent) => {
  const { data, loading } = useCulturalEventImagesQuery({ 
    variables: {
      culturalEventId: culturalEvent.id
    }})

  return $('div', { className: 'event-images-container'}, 
    $(Content, { 
      data, 
      loading, 
      culturalEvent 
    }))
}

const Content: FC<ContentProps> = ({ data, loading, culturalEvent }) => {
  if (loading && !data)
    return $(Skeleton, { className: 'event-image-item' })

  return $(Fragment, null,
    data?.culturalEventImages.map(ImageItem),
    $(UploadButton, culturalEvent))
}

const ImageItem = ({ id, image }: ImageProps) =>
  $('div', { 
    key: image.id, 
    style: { position: 'relative' }
    }, 
    $('div', { 
      style: { 
        position: 'absolute',
        right: 0,
        top: 0
      }},
      $(RemoveImageButtonWithConfirmation, { id })),
    $('img', { 
      src: image.url, 
      className: 'event-image-item'
    })
  )

type ContentProps = { 
  data: CulturalEventImagesQueryResult['data'], 
  loading: CulturalEventImagesQueryResult['loading'] 
  } & { culturalEvent: CulturalEventFragment }

type ImageProps = CulturalEventImagesQuery['culturalEventImages'][0]

export default Images
