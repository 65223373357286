import { SearchContext } from 'components/MobileMenu/SearchContext'
import { sanitizeInput } from 'components/MobileMenuContainer'
import { FabElement } from 'pages/Main/Desktop/FabContext'
import { createElement as $, FC, Fragment, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Props } from '..'
import Menu from './Menu'
import MobileFabContext, { MenuContext } from './MobileFabContext'
import Search from './Search'
import './style.scss'

const Mobile: FC<Props> = (props) => {
  const [search, setSearch] = useState('')
  const [mobileFab, setMobileFab] = useState<FabElement>()
  const [isSearchVisible, setSearchVisible] = useState(false)
  const propsWithFab: Props & { mobileFab: FabElement } = { ...props, mobileFab }
  const sanitizedSearch = sanitizeInput(search)
  const searchRegexp = new RegExp(sanitizedSearch, 'i')
  
  return $(Fragment, null,
    $(MenuContext.Provider, { value: setSearchVisible },
      $(SearchContext.Provider, { value: searchRegexp }, 
        $(MobileFabContext.Provider, { value: setMobileFab },
          $('div', { className: 'content' },
            $(Outlet)),
          isSearchVisible
            ? $(Search, { search, setSearch, setSearchVisible }) 
            : mobileFab && $(Menu, propsWithFab)
        ))))
}

export default Mobile