import { useApolloClient } from "@apollo/client"
import { Xmark } from "@gravity-ui/icons"
import { Button, ButtonProps, Icon } from "@gravity-ui/uikit"
import ConfirmDelete from "components/ConfirmDelete"
import MediaUploadButton from "components/MediaUploadButton/MediaUploadButton"
import useMediaUpload from "hooks/useMediaUpload"
import { AddRoomImageMutationVariables, RoomMediaFragment, RoomQuery, useAddRoomImageMutation, useRemoveRoomImageMutation } from "queries"
import { createElement as $, FC } from "react"
import './style.scss'

const Images: FC<RoomQuery> = (props) => {
  const client = useApolloClient()
  const [mutate] = useAddRoomImageMutation({ 
    onCompleted: () => client.reFetchObservableQueries() 
  })
  const { id: roomId, ziferblatId, media } = props.ziferblatRoomByPk!
  const variables = { roomId, ziferblatId } as AddRoomImageMutationVariables
  const { onChange, loading } = useMediaUpload({ mutate, variables })

  if (!props) return null
  return $('div', { className: 'room-images-container' }, 
    media.map(ImageItem),
    $(MediaUploadButton, { onChange, loading })
  )
}

const ImageItem: FC<RoomMediaFragment> = ({ media, id }) =>
  $('div', { 
    key: media.id, 
    style: { position: 'relative' }
    },
    $(RemoveImage, { id }),
    $('img', { 
      src: media.url, 
      className: 'room-image-item' 
    }))

const RemoveImage: FC<{ id: RoomMediaFragment['id'] }> = ({ id }) => {
  const client = useApolloClient()
  const [mutate] = useRemoveRoomImageMutation({ 
    variables: { id }, 
    onCompleted: () => client.reFetchObservableQueries()
  })

  return $(ConfirmDelete, {
    trigger: RemoveButton,
    onDelete: mutate,
    title: 'delete.image'
  })
}

const RemoveButton: FC<ButtonProps> = ({ onClick }) =>
  $(Button, {
    view: 'flat',
    size: 'xs',
    style: { position: 'absolute', right: 0, top: 0 },
    onClick
    }, 
    $(Icon, { data: Xmark }))

export default Images