import { Plus } from "@gravity-ui/icons"
import { Button, Icon, Sheet, spacing } from "@gravity-ui/uikit"
import { createElement as $, FC, Fragment, useState } from "react"
import NewRoom from "../NewRoom"

const AddRoom: FC = () => {
  const [visible, setVisible] = useState(false)

  return $(Fragment, null, 
    $(Button, { 
      pin: 'brick-round', 
      view: 'action', 
      size: 'l',
      onClick: () => setVisible(true)
    }, $(Icon, { data: Plus })),
    $(Sheet, { 
      visible, 
      onClose: () => setVisible(false)
      },
      $('div', { className: spacing({ p: 2 }) },
        $(NewRoom, { handleClose: () => setVisible(false) }))))
}

export default AddRoom