import { ArrowRightFromSquare } from "@gravity-ui/icons"
import { Avatar, Button, Flex, Icon, Link, Text } from "@gravity-ui/uikit"
import ControlsContainer from "components/FormControls/ControlsContainer"
import { MessageIds } from "components/IntlProvider"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import { AliasIteratee, ProfileProps } from "."

const Mobile: FC<ProfileProps> = ({ data, aliases, loading, name, surname, onClick }) => {
  const size = '8rem'
  const avatarDimensions = { width: size, height: size }
  const telegram = aliases.find(alias => alias.type === 'telegram_username')
  
  return $(Flex, null,
    $(Flex, {
      gap: 2,
      justifyContent: 'center', 
      alignItems: 'center', 
      width: '100%', 
      height: 'calc(100dvh - 4rem)' 
      },
      $(Flex, { 
        direction: 'column', 
        alignItems: 'center',
        gap: 4
        }, 
        $(Avatar, { 
          style: { ...avatarDimensions }, 
          imgUrl: data?.me?.user?.avatarUrl || '/favicon.svg' }),
        $(Flex, { direction: 'column', alignItems: 'flex-start' }, 
        $(Text, { 
          variant: 'header-1', 
          whiteSpace: 'break-spaces' 
        }, `${name} ${surname}`),
        $(Link, { 
          href: `https://t.me/${telegram?.value}`, 
          target: '__blank', 
          view: 'secondary', 
          style: { 
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            width: 'max-content'
          }}, 
          $(AliasIteratee, telegram))),
        data?.me?.user?.roles.map(({ role, ziferblat }) => 
          $(Flex, { 
            key: role.id, 
            alignItems: 'center', 
            gap: 2 
            },
            $(Avatar, { 
              size: 'xs',
              imgUrl: ziferblat?.avatarUrl || '/favicon.svg'
            }),
            ziferblat?.i18n[0].name,
            ': ',
            $(FormattedMessage, { id: `role.${role.name}` as MessageIds }))))),
    $(ControlsContainer, {
      title: 'back',
      button: $(LogoutButton, { onClick, loading })
    }))
}

const LogoutButton: FC<{ onClick: () => void, loading: boolean }> = ({ onClick, loading }) => 
  $(Button, { onClick, disabled: loading },
    $(FormattedMessage, { id: `logout${loading ? '.loading' : ''}` }),
    $(Icon, { data: ArrowRightFromSquare }))

export default Mobile