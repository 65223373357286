
import { House, Persons, Picture, TagRuble } from "@gravity-ui/icons"
import { Card, Flex, Icon, spacing, Text } from "@gravity-ui/uikit"
import { RoomsQuery } from "queries"
import { createElement as $, FC, PropsWithChildren } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

const RoomIteratee: FC<RoomsQuery['ziferblatRoom'][0]> = (props) => $(RoomLi, { key: props.id, ...props })

const RoomLi: FC<RoomsQuery['ziferblatRoom'][0]> = (props) => {
  const { locale } = useIntl()
  const navigate = useNavigate()
  const i18n = props.i18n?.find((i18n) => i18n.lang === locale)
  const image = props.media[0]?.media.url
  const size = 18

  return $(Card, {
    view: 'filled',
    type: 'action',
    className: spacing({ p: 3 }),
    style: { height: 'fit-content' },
    onClick: () => navigate(props.id),
    children: $(Flex, { gap: 3 },
      $(ImageContainer, null,
        image 
          ? $(Image, { src: image })
          : $(BlankImage)),
      $(Flex, {
        basis: '50%',
        gap: 2,
        direction: 'column', 
        justifyContent: 'space-between', 
        className: spacing({ pb: 1 })
        },
        $(Text, { variant: 'body-3' }, i18n?.name),
        $(Flex, { gap: 4, wrap: 'wrap' },
          $(Flex, { gap: 1 },
            $(Icon, { data: House, size }),
            $(Text, { variant: 'body-1' }, 
              props.area, 
              $(FormattedMessage, { id: 'area.squareMeter' }))),
          $(Flex, { gap: 1 },
            $(Icon, { data: Persons, size }),
            $(Text, { variant: 'body-1' }, props.capacity)),
          $(Flex, { gap: 1, grow: 1, width: '100%' }, 
            $(Icon, { data: TagRuble, size }),
            $(Text, { variant: 'body-1' }, 
              $(FormattedMessage, { 
                id: 'from.rublePerHour', 
                values: { cost: props.cost } 
              })
            ))
      )))
  })
}

const ImageContainer: FC<PropsWithChildren> = ({ children }) => 
  $(Flex, { 
    style: {
      borderRadius: 'var(--g-border-radius-l)',
      overflow: 'hidden',
      flexBasis: '50%',
      aspectRatio: '1/1'
    }}, children)

const Image = ({ src }: { src: string }) =>
  $('img', { 
    src, 
    style: {
      aspectRatio: '1/1', 
      objectFit: 'cover' 
    }})

const BlankImage = () => 
  $(Flex, { 
    style: {
      border: '2px solid var(--g-color-line-generic)', 
      borderRadius: 'var(--g-border-radius-l)',
      width: '100%'
    },
    justifyContent: 'center', 
    alignItems: 'center' 
    }, 
    $(Text, { color:'hint' }, $(Icon, { data: Picture, size: 32 })))

export default RoomIteratee