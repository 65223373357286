import MediaUploadButton from "components/MediaUploadButton/MediaUploadButton"
import useMediaUpload from "hooks/useMediaUpload"
import { AddCulturalEventImageMutationVariables, CulturalEventFragment, useAddCulturalEventImageMutation } from "queries"
import { createElement as $, FC } from "react"

const UploadButton: FC<CulturalEventFragment> = (props) => {
  const [mutate] = useAddCulturalEventImageMutation({
    updateQueries: {
      CulturalEventImages: (prev, { mutationResult }) => {
        const images = [...prev.culturalEventImages, mutationResult.data?.addCulturalEventImage]

        return { culturalEventImages: images } 
      }
    }
  })
  const variables = { 
    ziferblatId: props.ziferblatId, 
    culturalEventId: props.id 
  } as AddCulturalEventImageMutationVariables
  
  const { onChange, loading } = useMediaUpload({ mutate, variables })

	return $(MediaUploadButton, { onChange, loading })
} 

export default UploadButton