import { TrashBin, Xmark } from "@gravity-ui/icons"
import { Button, ButtonProps, Flex, Icon, Modal, Sheet, spacing, useLayoutContext } from "@gravity-ui/uikit"
import { createElement as $, FC, Fragment, useState } from "react"
import { FormattedMessage } from "react-intl"
import { MessageIds } from "./IntlProvider"

const ConfirmDelete: FC<Props> = ({ trigger, onDelete, loading, title }) => {
  const [open, setOpen] = useState(false)
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop

  return $(Fragment, null, 
    $(trigger, { onClick: () => setOpen(true) }),
    $(component, { open, setOpen, onDelete, loading, title }))
}

const Mobile: FC<ContentProps> = ({ open, setOpen, onDelete, loading }) => 
  $(Sheet, { 
    visible: open, 
    onClose: () => setOpen(false) 
    },
    $(Flex, { direction: 'column', gap: 2, className: spacing({ p: 2 }) }, 
      $(Button, { 
        onClick: onDelete, 
        loading,
        size: 'l',
        view: 'action'
        },
        $(Icon, { data: TrashBin }),
        $(FormattedMessage, { id: 'delete' })),
      $(Button, { 
        onClick: () => setOpen(false),
        size: 'l'
        },
        $(Icon, { data: Xmark }),
        $(FormattedMessage, { id: 'cancel' }))))

const Desktop: FC<ContentProps> = ({ open, setOpen, onDelete, loading, title }) => 
  $(Modal, { 
    open, 
    onClose: () => setOpen(false),
    },
    $(Flex, { 
      direction: 'column', 
      gap: 4, 
      alignItems: 'center', 
      className: spacing({ p: 4 }) 
      },
      title && $(FormattedMessage, { id: title }), 
      $(Flex, { gap: 2 },
        $(Button, { 
          view: 'action',
          onClick: onDelete,
          loading
          },
          $(Icon, { data: TrashBin }),
          $(FormattedMessage, { id: 'delete' })), 
        $(Button, { 
          view: 'outlined',
          onClick: () => setOpen(false),
          disabled: loading
          }, 
          $(Icon, { data: Xmark }),
          $(FormattedMessage, { id: 'cancel' })))))

type Props = {
  trigger: FC<ButtonProps>
  onDelete: () => void
  loading?: boolean,
  title?: MessageIds
}

type ContentProps = {
  open: boolean
  setOpen: (arg: boolean) => void
} & Pick<Props, 'onDelete' | 'loading' | 'title'>

export default ConfirmDelete