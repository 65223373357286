import { Button, Card, Flex, spacing } from '@gravity-ui/uikit'
import { useFormikContext } from 'formik'
import { createElement as $, FC, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { CulturalEventProps } from '.'
import CulturalEventForm, { Form } from './CulturalEventForm'
import Images from './Images'
import StatusSelect from './StatusSelect'

const CulturalEvent: FC<CulturalEventProps> = ({ culturalEvent }) =>
  $(Flex, { gap: 4, width: '90vw' },
    $(Flex, null, 
      $(Card, {
        width: '500px',
        height: 'fit-content',
        className: spacing({ p: 4 }), 
        view: 'raised',
        children:
          $(Fragment, null,
            $(CulturalEventForm),
            $(Flex, { justifyContent: 'space-between'}, 
              $(SaveButton),
              $(StatusSelect)))
    })),
    $(Images, culturalEvent))

const SaveButton: FC = () => {
  const intl = useIntl()
  const formik = useFormikContext<Form>()

  return $(Button, {
    loading: formik.isSubmitting,
    disabled: !formik.dirty || !formik.isValid,
    onClick: formik.submitForm,
    view: 'outlined-action',
    },
    intl.formatMessage({ id: 'culturalEvent.form.save' }))
}

export default CulturalEvent