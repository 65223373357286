import { Sheet } from '@gravity-ui/uikit'
import { StaffQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import '../style.scss'
import AddStaff from '../AddStaff'

const Edit: FC<StaffQuery> = (data) => {
  const match = useMatch('/settings/staff/:page')
  const navigate = useNavigate()
  if (!data) return null
  return $(Sheet, {
    className: 'staff_add-staff_sheet',
    visible: !!match?.params.page,
    onClose: () => navigate('/settings/staff')
    },
    $(Routes, null,
      $(Route, {
        path: addPath,
        element: $(AddStaff, data)
        })))
}

export const addPath = 'add'

export default Edit