import { TrashBin } from "@gravity-ui/icons"
import { Button, Flex, Icon } from "@gravity-ui/uikit"
import { useFormikContext } from "formik"
import { useRemoveBookingMutation } from "queries"
import { createElement as $, FC, PropsWithChildren } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { Form } from "./BookingForm"
import ConfirmDelete from "components/ConfirmDelete"

const DesktopFormControls: FC<PropsWithChildren> = ({ children }) => {
  const { dirty, submitForm, isSubmitting } = useFormikContext<Form>()
  const intl = useIntl()

  return $(Flex, { justifyContent: 'space-between', height: 'fit-content' }, 
    children,
    $(Flex, { gap: 2, justifyContent: 'space-between', width: '100%' }, 
      $(DeleteButton),
      $(Button, { 
        view: 'action', 
        disabled: !dirty,
        loading: isSubmitting,
        onClick: submitForm
      }, intl.formatMessage({ id: 'form.save' }))))
}

export const DeleteButton = () => {
  const params = useParams<'id'>()
	const id = params.id || ''
  const { values } = useFormikContext<Form>()
  const [mutate, { loading }] = useRemoveBookingMutation({ variables: { id }})
  const navigate = useNavigate()

  if (params.id === 'new' || values.hasCheckins ) 
    return $('div', { style: { flexGrow: 1 }})

  return $(ConfirmDelete, { 
    trigger: (props) => 
      $(Button, { 
        view: 'outlined-danger', 
        ...props 
        }, 
        $(Icon, { data: TrashBin })),
    onDelete: () => mutate().then(() => navigate('/calendar')),
    title: 'delete.booking',
    loading
  })
}

export default DesktopFormControls