import { Magnifier, Plus } from '@gravity-ui/icons'
import { Button, Flex, Icon } from '@gravity-ui/uikit'
import CallToAction from 'components/MobileMenu/CallToAction'
import useFab from 'hooks/useFab'
import MobileFabContext, { MenuContext } from 'pages/Settings/Mobile/MobileFabContext'
import { createElement as $, FC, Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Content, { StaffProps } from '../Content'
import Edit from './Edit'

const Mobile: FC<StaffProps> = (props) => {
  useFab($(AddButton), MobileFabContext)
  
  return $(Fragment, null, 
    $(Edit, props.data),
    $(Content, props))
}

const AddButton = () => {
  const navigate = useNavigate()
  const setSearchVisible = useContext(MenuContext)

  return $(Flex, { position: 'relative' }, 
    $(Button, {
      size: 'l',
      view: 'flat',
      onClick: () => setSearchVisible(true),
      style: {
        position: 'absolute',
        left: '-3rem'
      }},
      $(Icon, { data: Magnifier })),
    $(CallToAction, {
      data: Plus,
      onClick: () => navigate(`/settings/staff/add`)
  }))
}

export default Mobile