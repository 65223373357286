import { CopyCheck } from '@gravity-ui/icons'
import { Flex, Icon, Text, spacing, useLayoutContext } from '@gravity-ui/uikit'
import { CheckinFragment } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import CheckinListItemIteratee from './CheckinListItem'
import { Desktop, Mobile } from './CheckoutMultiple'
import Content from './CheckoutMultiple/Content'
import { CheckinsContainer } from './Desktop'
import { Group } from './groupedCheckins'

const CheckinsGroup: FC<Group<CheckinFragment>> = (props) => {
  const intl = useIntl()
  const isBooking = props.type === 'booking'
  const comment = isBooking 
    ? props.checkins[0].checkinBooking?.booking.comment
    : props.checkins[0].attendance[0]?.culturalEvent?.internalComment
  const phone = props.checkins[0].checkinBooking?.booking.phone
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const { activeMediaQuery } = useLayoutContext()
  const justify = activeMediaQuery === 's' ? 'space-between' : 'flex-start'
  const component = activeMediaQuery === 's' ? Mobile : Desktop
 
  return $('div', { className: spacing({ mb: 2 }) },
    $(Flex, { 
      direction: 'column', 
      className: spacing({ pl: 4, pr: 2, pb: 2 }),
      style: { 
        cursor: comment || isBooking ? 'pointer' : 'default',
        WebkitTapHighlightColor: 'transparent'
      },
      onClick: () => {
        isBooking
          ? setOpen(true)
          : setExpanded(!expanded)
      }},
      $(Flex, {
        gap: 2, 
        alignItems: 'center', 
        justifyContent: justify,
        },
        $(Text, null, props.name || intl.formatMessage({ id: 'calendarEvent.noName' })),
        $(Flex, {
          alignItems: 'center', 
          overflow: 'hidden', 
          }, 
          $(Text, { color: 'hint', variant: 'subheader-1' }, props.checkins.length),
          isBooking && 
            $(Fragment, null,
              $(Icon, { data: CopyCheck, className: spacing({ m: 1 }) }),
              $(component, { open, setOpen }, 
                $(Content, { 
                  name: props.name, 
                  phone,
                  comment, 
                  checkins: props.checkins, 
                  setOpen 
                }))))),
      comment && 
        $(Text, {
          as: 'div',
          variant: 'caption-2', 
          color: 'hint', 
          whiteSpace: 'break-spaces',
          ellipsisLines: !expanded ? 2 : undefined,
          style: { width: '100%' },
        }, comment)),
    $(CheckinsContainer, null,
      props.checkins.map(CheckinListItemIteratee)))
}

export const GroupIteratee = (props: Group<CheckinFragment>) =>
  $(CheckinsGroup, { key: props.name, ...props })
