import { Gear, Person } from '@gravity-ui/icons'
import Calendar from '@gravity-ui/icons/Calendar'
import Persons from '@gravity-ui/icons/Persons'
import { IconData } from '@gravity-ui/uikit'
import CalendarEvents from 'pages/CalendarEvents'
import Checkins from 'pages/Checkins'
import Profile from 'pages/Profile'
import Settings from 'pages/Settings'
import { createElement as $, ReactElement } from 'react'

export const paths: Record<string, PathConfig> = {
  checkins: {
    icon: Persons,
    element: $(Checkins),
    label: 'main.checkins',
  },
  calendar: {
    icon: Calendar,
    element: $(CalendarEvents),
    label: 'main.calendar',
  },
  settings: {
    icon: Gear,
    element: $(Settings),
    label: 'main.settings',
  },
  profile: {
    icon: Person,
    element: $(Profile),
    label: 'main.profile',
    hidden: true
  }
}

export const pathEntries = Object.entries(paths)

export type PathConfig = {
  icon: IconData
  element: ReactElement
  label: FormatjsIntl.Message['ids']
  disabled?: boolean
  hidden?: boolean
}

export type PathConfigEntry = typeof pathEntries[number]