import { Plus } from "@gravity-ui/icons"
import { Button, Icon, Modal, spacing } from "@gravity-ui/uikit"
import useFab from "hooks/useFab"
import { RoomsQuery } from "queries"
import { createElement as $, FC, Fragment, useState } from "react"
import NewRoom from "./NewRoom"
import RoomIteratee from "./RoomIteratee"
import './style.scss'

const Desktop: FC<RoomsQuery> = (data) => {
  useFab($(AddRoom))

  if (!data.ziferblatRoom) return null
  
  return $('div', { className: 'rooms-container' }, 
    data.ziferblatRoom.map(RoomIteratee))
}

export const AddRoom = () => {
  const [open, setOpen] = useState(false)

  return $(Fragment, null, 
    $(Button, { 
      view: 'action', 
      size: 'xl',
      onClick: () => setOpen(true)
      }, 
      $(Icon, { data: Plus })),
    $(Modal, { 
      open,
      onOutsideClick: () => setOpen(false) 
      },
      $('div', { className: spacing({ p: 4 }) },
        $(NewRoom, { handleClose: () => setOpen(false) }))))
}

export default Desktop