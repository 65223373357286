import { Plus } from '@gravity-ui/icons'
import { Button, Icon } from '@gravity-ui/uikit'
import useFab from 'hooks/useFab'
import MobileFabContext from 'pages/Settings/Mobile/MobileFabContext'
import { createElement as $, FC, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import AddTariff from '../AddTariff'
import Content, { TariffsProps } from './Content'


const Mobile: FC<TariffsProps> = (props) => {
  useFab($(AddButton), MobileFabContext)
  
  return $(Fragment, null, 
    $(Content, props),
    $(AddTariff, props))
}

const AddButton = () => {
  const navigate = useNavigate()
  return $(Button, {
    pin: 'brick-round',
    view: 'action',
    size: 'l',
    onClick: () => navigate(`/settings/tariff/add`)
  }, $(Icon, { data: Plus }))
}

export default Mobile