import PlusIcon from '@gravity-ui/icons/Plus'
import { Button, Icon, Modal, Sheet, spacing, useLayoutContext } from '@gravity-ui/uikit'
import useFab from 'hooks/useFab'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { TariffsProps } from './Mobile/Content'
import TariffForm from './TariffForm'

const basepath = '/settings/tariff/'
const addPath = `${basepath}add`

const AddTariff: FC<TariffsProps> = (props) => {
  const match = useMatch(addPath)
  const navigate = useNavigate()
  const { activeMediaQuery } = useLayoutContext()
  const open = !!match
  const toggleOpen = () => navigate(open ? basepath : addPath)
  const children = $(TariffForm, { onSuccess: toggleOpen, props })
  const component = activeMediaQuery === 's'
    ? AddTariffMobile
    : AddTariffDesktop

  return $(component, { open, toggleOpen, children })
}

const AddTariffDesktop: FC<AddTariffProps> = ({
  open,
  toggleOpen,
  children
}) => {
  useFab($(Button, {
    onClick: toggleOpen,
    view: 'action',
    size: 'xl'
    },
    $(Icon, { data: PlusIcon })))

  return $(Modal, { 
    open, 
    onClose: toggleOpen,
    }, 
    $('div', { className: spacing({ p: 4 }) }, 
      children))
}

const AddTariffMobile: FC<AddTariffProps> = ({
  open,
  toggleOpen,
  children
}) => 
  $(Sheet, { 
    visible: open, 
    onClose: toggleOpen
    },
    $('div', { className: spacing({ p: 2 }) }, 
      children))

type AddTariffProps = PropsWithChildren<{
  open: boolean
  toggleOpen: () => void
}>

export default AddTariff