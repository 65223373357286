import { ArrowLeft } from "@gravity-ui/icons"
import { Button, Card, CardProps, Icon, Text } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import useBack from "hooks/useBack"
import { createElement as $, FC, Fragment, ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import './style.scss'

const ControlsContainer: FC<Props> = ({ button, props, title }) => {
  const back = useBack()

  return $(Card, {
    className: 'controls',
    view: 'filled',
    type: 'action',
    ...props,
    children: $(Fragment, null, 
      $(Button, {
        className: 'controls_button',
        size: 'l',
        view: 'flat',
        onClick: () => back({ fallback: '/' })
        },
        $(Icon, { data: ArrowLeft }),
        $(Text, null, $(FormattedMessage, { id: title }))),
      button)
    })
}

type Props = {
  button: ReactElement
  title: MessageIds
  props?: Omit<CardProps, 'children'>
}

export default ControlsContainer