import { useLayoutContext } from "@gravity-ui/uikit"
import { useRoomsQuery } from "queries"
import { createElement as $, FC } from "react"
import { DataProps } from ".."
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import { Routes, Route } from "react-router-dom"
import Room from "./Room"

const Rent: FC<DataProps> = ({ id }) => {
  const { data } = useRoomsQuery({ variables: { id }})
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop

  return $(Routes, null,
    $(Route, {
      path: '*',
      element: $(component, data)
    }),
    $(Route, {
      path: ':id',
      element: $(Room)
    })
  )
}

export default Rent