import { FloppyDisk } from "@gravity-ui/icons"
import { Button, Icon } from "@gravity-ui/uikit"
import { useFormikContext } from "formik"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import RoomForm from "./Room/RoomForm"
import RoomWithData from "./Room/RoomWithData"

const NewRoom: FC<Props> = ({ handleClose }) =>
  $(RoomWithData, null, 
    $(RoomForm),
    $(SaveButton, { handleClose }))

const SaveButton: FC<Props> = ({ handleClose }) => {
  const { dirty, errors, submitForm, isSubmitting } = useFormikContext()
  const hasErrors = !!Object.values(errors).length
  const onClick = () => submitForm().then(() => handleClose())

  return $(Button, {
    size: 'l',
    width: 'max',
    onClick,
    disabled: !dirty || hasErrors,
    loading: isSubmitting,
    view: 'action'
    },
    $(Icon, { data: FloppyDisk }),
    $(FormattedMessage, { id: 'form.save' }))
}

type Props = {
  handleClose: () => void
}

export default NewRoom