import useFab from "hooks/useFab"
import MobileFabContext from "pages/Settings/Mobile/MobileFabContext"
import { RoomsQuery } from "queries"
import { createElement as $, FC } from "react"
import RoomIteratee from "../RoomIteratee"
import '../style.scss'
import AddRoom from "./AddRoom"

const Mobile: FC<RoomsQuery> = (data) => {
  useFab($(AddRoom), MobileFabContext)

  if (!data.ziferblatRoom) return null

  return $('div', { className: 'rooms-container' }, 
    data.ziferblatRoom.map(RoomIteratee))
}

export default Mobile