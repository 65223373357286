import { MutationHookOptions } from "@apollo/client"
import { useImageUploadUrlMutation } from "queries"
import { ChangeEvent, useState } from "react"

const useMediaUpload = ({ mutate, variables }: HookProps) => {
  const [getUploadUrl] = useImageUploadUrlMutation()
  const [loading, setLoading] = useState(false)

  window.onbeforeunload = () => loading ? true : undefined

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const files = event.target.files || []
    
    try {
      for (const file of files) {
        const { data } = await getUploadUrl()
        
        if (!data?.getUploadUrl) 
          return console.log('no data')
        
        const { url, id } = data?.getUploadUrl
        const contentType = file.type

        if (url) {
          setLoading(true)
          await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'image/png'
            }
          })
          await mutate({
            variables: {
              id,
              contentType,
              ...variables
            }
          }).then(() => setLoading(false))
        }
      }
    }
    catch (error) { 
      console.log(error) 
    }
  }
  
  return { onChange, loading }
}

type HookProps = {
  mutate: (arg: MutationHookOptions<any, any>) => Promise<any>, 
  variables: Record<string, any> 
}

export default useMediaUpload