import { Flex, Link } from '@gravity-ui/uikit'
import DateField from 'components/FormikFields/Date'
import TextField from 'components/FormikFields/TextField'
import FormRow from 'components/FormRow'
import { Formik, FormikValues } from 'formik'
import { useUpsertZiferblatI18nMutation, useZiferblatDescriptionQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DataProps } from '..'
import SaveButton from '../SaveButton'

const Description: FC<DataProps> = ({ id }) => {
  const { data, loading } = useZiferblatDescriptionQuery({ variables: { id }})
  const [mutate] = useUpsertZiferblatI18nMutation()
  const { locale } = useIntl()
  const ziferblat = data?.ziferblat

  if (!ziferblat || loading) return

  const i18n = ziferblat.i18n[0]

  const initialValues = {
    name: i18n.name,
    description: i18n.description,
    rules: i18n.rules,
    activeFrom: ziferblat.activeFrom
  }

  const onSubmit = ({ name, description, rules }: FormikValues) => mutate({
    updateQueries: {
      ZiferblatDescription: (prev, { mutationResult }) => {
        const prevData = prev.ziferblat.i18n[0]
        const newData = mutationResult.data?.insertZiferblatI18n
        const mergedI18n = { ...prevData, ...newData }
        const result = { ziferblat: {...prev.ziferblat, i18n: [mergedI18n] }}
        
        return result
      },
    },
    variables: {
      data: { 
        name,
        description,
        rules,
        ziferblatId: id,
        locale
      },
    }})

  return $(Formik, { 
    initialValues, 
    onSubmit, 
    enableReinitialize: true, 
    validateOnBlur: true
    },
    $(Flex, { direction: 'column' },
      $(FormRow, { label: 'ziferblat.form.name' },
        $(TextField, { name: 'name' })),
      $(FormRow, { label: 'ziferblat.form.description' },
        $(TextField, { name: 'description', note })),
      $(FormRow, { label: 'ziferblat.form.rules' },
        $(TextField, { name: 'rules' })),
      $(FormRow, { label: 'ziferblat.form.activeFrom' },
        $(DateField, { name: 'activeFrom', disabled: true })),
      $(SaveButton)))
}

const note = $(FormattedMessage, {
  id: 'forms.note.markdown', 
  values: {
    link: $(Link, {
      key: 'description',
      href: 'https://www.markdownguide.org/cheat-sheet', 
      target: '_blank' 
    }, $(FormattedMessage, { id: 'forms.markdown' }))
  }})

export default Description
