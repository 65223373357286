import { useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { useStaffQuery } from 'queries'
import { createElement as $, useContext } from 'react'
import Desktop from './Desktop'
import Mobile from './Mobile'
import './style.scss'

const Staff = () => {
  const { ziferblatId } = useContext(ZiferblatContext)
  const props = useStaffQuery({ variables: { ziferblatId } })
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  
  return $(component, props)
}

export default Staff