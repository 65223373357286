
import { Flex, Link } from '@gravity-ui/uikit'
import TextField from "components/FormikFields/TextField"
import FormRow from "components/FormRow"
import { MessageIds } from 'components/IntlProvider'
import { FormikValues, useFormikContext } from 'formik'
import { createElement as $ } from "react"
import { FormattedMessage } from "react-intl"

const RoomForm = () => {
  const { errors } = useFormikContext()

  return $(Flex, { direction: 'column' },
    $(FormRow, { label: 'ziferblat.form.room.name' },
      $(TextField, { 
        name: 'name',
        autoFocus: true,
        validate: (value) => !value ? 'required' : undefined,
        errorMessage: $(FormattedMessage, { id: 'errors.required' })
      })),
    $(FormRow, { label: 'ziferblat.form.room.description' },
      $(TextField, { 
        name: 'description',
        minRows: 2,
        note: $(FormattedMessage, {
          id: 'forms.note.markdown', 
          values: {
            link: $(Link, {
              key: 'description', 
              href: 'https://www.markdownguide.org/cheat-sheet', 
              target: '_blank' 
            }, $(FormattedMessage, ({ id: 'forms.markdown' })))
          }})
      })),
    $(FormRow, { label: 'ziferblat.form.room.area' },
      $(TextField, { 
        name: 'area', 
        validate, 
        errorMessage: getErrorMessage('area', errors) 
      })),
    $(FormRow, { label: 'ziferblat.form.room.capacity' },
      $(TextField, { 
        name: 'capacity', 
        validate, 
        errorMessage: getErrorMessage('capacity', errors) 
      })),
    $(FormRow, { label: 'ziferblat.form.room.cost' },
      $(TextField, { 
        name: 'cost', 
        validate, 
        errorMessage: getErrorMessage('cost', errors) 
      })))
}

const getErrorMessage = (name: string, errors: Record<string, string>) => {
  const error = errors[name]
  const messages: Record<string, MessageIds> = {
    required: 'errors.required',
    NaN: 'errors.mustBeNumber',
  }
  const id: MessageIds = messages[error]

  if (!error || !id) return undefined
  return $(FormattedMessage, { id })
}

const validate = (value: FormikValues[number]) => {
  const errors: Record<string, string> = {}
  const isEmpty = / /.test(value)

  if (!value || isEmpty) errors[value] = 'required'
  if (isNaN(value)) errors[value] = 'NaN'

  return errors[value]
}

export default RoomForm