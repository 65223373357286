import { Check, FloppyDisk } from "@gravity-ui/icons"
import { Button, CardProps, Icon } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import { useFormikContext } from "formik"
import { createElement as $, FC, useEffect, useState } from "react"
import ControlsContainer from "./ControlsContainer"
import './style.scss'

const FormControls: FC<Props> = ({ loading, title, props }) => {
  const { dirty, isValid, isSubmitting, submitForm } = useFormikContext()
  const [submitted, setSubmitted] = useState(false)

  const onClick = () => {
    if (!submitted) 
      submitForm().then(() => setSubmitted(true))
  }

  useEffect(() => {
    setTimeout(() => { 
      setSubmitted(false) 
    }, 2000)
  }, [isSubmitting])

  return $(ControlsContainer, { 
    title,
    props,
    button: $(Button, {
      size: 'l',
      view: `outlined-${submitted ? `success` : `action`}`,
      onClick,
      loading: loading || isSubmitting,
      disabled: (!submitted && !dirty) || !isValid
      },
      $(Icon, { data: submitted ? Check : FloppyDisk }))
  })
}

type Props = {
  loading?: boolean
  title: MessageIds,
  props?: Omit<CardProps, 'children'>
}

export default FormControls