import { Plus } from "@gravity-ui/icons"
import { Button, ButtonProps, Spin } from "@gravity-ui/uikit"
import { createElement as $, FC, InputHTMLAttributes, useRef } from "react"
import './style.scss'

const MediaUploadButton: FC<UploadButtonProps> = ({ loading, onChange, style }) => {
  const ref = useRef<HTMLInputElement>(null)
  
  const onClick = () => {
		if (ref.current) {
			ref.current.click()
		}
	}

  return $(Button, {
    view: 'outlined',
    className: 'add-images-button',
    onClick,
    style
    },
		loading 
      ? $(Spin) 
      : $(Plus, {
			  className: 'add-images-button_plus-icon',
		  }),
		$('input', {
			ref,
			className: 'add-images-button_input',
			type: 'file',
			multiple: true,
			onChange,
		})
	)
}

type UploadButtonProps = ButtonProps & InputHTMLAttributes<HTMLInputElement>

export default MediaUploadButton