import UserContent from 'components/User'
import useNavItems from 'hooks/useNavItems'
import { useMeQuery } from 'queries'
import { createElement as $, Fragment } from 'react'
import { useIntl } from 'react-intl'
import MobileNavigation from './MobileNavigation'

const MenuContent = () => {
  const { locale } = useIntl()
  const { data } = useMeQuery({ 
    fetchPolicy: 'cache-first', 
    variables: { 
      domain: window.location.hostname,
      locale
    } 
  })
  const navItems = useNavItems()

  if (!data) return null
  return $(Fragment, null,
    $(MobileNavigation, { navItems }),
    $(UserContent, data))
}

export default MenuContent