import { Flex, Skeleton } from '@gravity-ui/uikit'
import { createElement as $ } from 'react'
import './style.scss'

export const SkeletonCard = (value: number, index: number) =>
  $(Skeleton, {
    key: index,
    style: {
      borderRadius: 'var(--g-border-radius-m)',
      // height: 'calc((var(--g-spacing-4) * 2) + var(--g-text-body-2-line-height) + var(--g-text-caption-2-line-height))'
      height: '100px'
      }})

const getSkeletons = (value: number) => new Array(value).fill(0).map(SkeletonCard)

const loadingElements = 
  $('div', { className: 'loading-elements' }, 
    getSkeletons(25))

export const gridLoadingElements =
  $('div', { className: 'grid-loading-elements' }, 
    getSkeletons(25))

export const eventsLoadingElements =
  $(Flex, { 
    gap: 2, 
    className: 'dates-loading-elements',
    },
    $(Flex, { 
      direction: 'column', 
      grow: 2, 
      gap: 2, 
      minWidth: '128px',
    }, getSkeletons(10)),
    $(Flex, { direction: 'column', grow: 8, gap: 2 },
      getSkeletons(10)))

export default loadingElements