import { TextArea, TextAreaProps } from '@gravity-ui/uikit'
import { Field, FieldProps, FormikValues } from 'formik'
import { createElement as $, FC } from 'react'

const TextField: FC<
  TextAreaProps & { 
    name: string, 
    validate?: (value: FormikValues[number]) => string | undefined 
  }> = ({ name, validate, ...props }) => 
  $(Field, {
    name,
    validate,
    children: (field: FieldProps) =>
      $(GravityTextField, { field, props })
  })

const GravityTextField: FC<Props> = ({
  field,
  props
}) =>
  $(TextArea, {
    ...props,
    ...field.field,
    error: field.meta.error,
    onUpdate: (value) => field.form.setFieldValue(field.field.name, value)
  })

type Props = {
  field: FieldProps<string>,
  props: TextAreaProps
}

export default TextField;