import { FloppyDisk } from "@gravity-ui/icons"
import { Button, ButtonProps, Icon, useLayoutContext } from "@gravity-ui/uikit"
import { FormikProps, useFormikContext } from "formik"
import useFab from "hooks/useFab"
import FabContext from "pages/Main/Desktop/FabContext"
import { createElement as $, FC } from "react"
import MobileFabContext from "./Mobile/MobileFabContext"

const SaveButton = () => {
  const formik = useFormikContext()
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's' 
  const context = isMobile 
    ? MobileFabContext
    : FabContext

  const style: ButtonProps = isMobile 
    ? { size: 'l', pin: 'brick-round' }
    : { size: 'xl', pin: 'round-round' }

  useFab($(Save, { style, ...formik }), context)

  return null
}

const Save: FC<FormikProps<any> & { style: any }> = ({ 
  submitForm, 
  isValid, 
  dirty, 
  isSubmitting, 
  style 
}) => $(Button, { 
  onClick: submitForm, 
  view: 'action', 
  ...style,
  disabled: !dirty || !isValid,
  loading: isSubmitting
  }, 
  $(Icon, { data: FloppyDisk }))

export default SaveButton