import ZiferblatContext from "components/ZiferblatContext"
import { FormikProvider, FormikValues, useFormik } from "formik"
import { RoomQuery, useAddRoomMutation, ZiferblatRoom, ZiferblatRoomI18nConstraint, ZiferblatRoomI18nUpdateColumn } from "queries"
import { createElement as $, FC, PropsWithChildren, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

const RoomWithData: FC<PropsWithChildren<RoomQuery | undefined>> = ({ ziferblatRoomByPk, children }) => {
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate] = useAddRoomMutation()
  const { locale } = useIntl()
  const navigate = useNavigate()
 
  const room = ziferblatRoomByPk || {
    id: undefined,
    ziferblatId: undefined,
    area: '',
    capacity: '',
    cost: '',
    i18n: []
  }

  const i18n = room.i18n?.find((i18n) => i18n.lang === locale) || {
    id: undefined,
    name: '',
    description: '',
    lang: locale
  }

  const initialValues = {
    name: i18n.name || '',
    description: i18n.description || '',
    area: room.area?.toString() || '',
    capacity: room.capacity?.toString() || '',
    cost: room.cost?.toString() || ''
  }

  const formik = useFormik<any>({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: ({ 
      name, 
      description, 
      area, 
      capacity, 
      cost
    }: FormikValues) => 
      mutate({
        updateQueries: {
          Room: (prev, { mutationResult }) => {
            const next = mutationResult.data?.insertZiferblatRoomOne
            return { ziferblatRoomByPk: { ...prev.ziferblatRoomByPk, ...next }}
          },
          Rooms: (prev, { mutationResult }) => {
            const existing = prev.ziferblatRoom
            const next = mutationResult.data?.insertZiferblatRoomOne
            const isNotNew = prev.ziferblatRoom.some((room: ZiferblatRoom) => room.id === next?.id)
            
            if (isNotNew) return prev
            return { ziferblatRoom: [...existing, next]}
          }
        },
        variables: { 
          object: {
            id: room.id,
            ziferblatId,
            area: parseInt(area),
            capacity: parseInt(capacity),
            cost: parseInt(cost),
            i18n: {
              data: [{
                id: i18n.id,
                name, 
                description, 
                lang: locale
              }],
              onConflict: {
                constraint: ZiferblatRoomI18nConstraint['RoomI18nPkey'],
                updateColumns: [
                  ZiferblatRoomI18nUpdateColumn['Name'], 
                  ZiferblatRoomI18nUpdateColumn['Description'],
                  ZiferblatRoomI18nUpdateColumn['Lang']
                ]
              },
            }
          }
    }}).then(({data}) => navigate(`/settings/rent/${data?.insertZiferblatRoomOne?.id}`))
  })
  
  return $(FormikProvider, { value: formik }, children)
}

export default RoomWithData