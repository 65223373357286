import { Xmark } from '@gravity-ui/icons'
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit'
import ConfirmDelete from 'components/ConfirmDelete'
import { CulturalEventImage, useRemoveCulturalEventImageMutation } from 'queries'
import { createElement as $, FC } from 'react'
import './style.scss'

const RemoveImageButtonWithConfirmation: FC<{ id: string }> = ({ id }) => {
  const [mutate, { loading }] = useRemoveCulturalEventImageMutation({ 
    variables: { id },
    updateQueries: {
      CulturalEventImages: (prev, { mutationResult }) => {
        const removed = mutationResult.data?.removeCulturalEventImage
        
        if (!removed) return prev
        return { culturalEventImages: 
          prev.culturalEventImages.filter((image: CulturalEventImage) =>
            image.id !== removed?.id) 
        }
      }
    }
  })

  return $(ConfirmDelete, {
    trigger: RemoveButton,
    onDelete: mutate,
    loading,
    title: 'delete.image'
  })
}

const RemoveButton: FC<ButtonProps> = ({ onClick }) =>
  $(Button, {
    view: 'flat',
    size: 'xs',
    onClick
    }, 
    $(Icon, { data: Xmark }))

export default RemoveImageButtonWithConfirmation