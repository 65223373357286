import MobileMenu, { MobileMenuVisualProps } from 'components/MobileMenu'
import { SearchContext } from 'components/MobileMenu/SearchContext'
import { createElement as $, FC, PropsWithChildren, useState } from 'react'
import './style.scss'

const MobileMenuContainer: FC<MobileMenuContainerProps> = ({
  children,
  label,
  fab
}) => {
  const [search, setSearch] = useState('')
  const sanitizedSearch = sanitizeInput(search)
  const searchRegexp = new RegExp(sanitizedSearch, 'i')

  return $('div', { className: 'mobile-menu-container' },
    $(SearchContext.Provider, { value: searchRegexp, children }),
    $('div', { className: 'mobile-menu-container_menu' },
      $(MobileMenu, {
        fab,
        label,
        search,
        setSearch
        })))
}

const DISALLOWED_CHARACTERS = /[^\p{L}\p{N}]+/giu

export const sanitizeInput = (value: string) => 
  value.trim().replace(DISALLOWED_CHARACTERS, '')

type MobileMenuContainerProps = PropsWithChildren<MobileMenuVisualProps>

export default MobileMenuContainer