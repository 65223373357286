import { ApolloProvider } from '@apollo/client'
import { Spin } from '@gravity-ui/uikit'
import '@gravity-ui/uikit/styles/fonts.css'
import '@gravity-ui/uikit/styles/styles.css'
import useCreateClient, { TOKEN_KEY } from 'apolloClient'
import IntlProvider from 'components/IntlProvider'
import MessageContainer from 'components/MessageContainer'
import ThemedApp from 'components/ThemedApp'
import Unauthorized from 'components/Unauthorized'
import { ROLE_KEY } from 'components/User'
import ZiferblatContext from 'components/ZiferblatContext'
import Login from 'pages/Login'
import Main from 'pages/Main'
import { RoleType } from 'pages/Settings/Staff/StaffListItem'
import { useMeQuery } from 'queries'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

const App: FC<PropsWithChildren> = () => {
  const url = `wss://${process.env['REACT_APP_API']}`
  const client = useCreateClient(url)

  return $(ThemedApp, null,
    $(BrowserRouter, null,
      $(IntlProvider, null,
        $(ApolloProvider, {
          client,
          children: $(LoginCheck)
        }))))
}

const LoginCheck = () => {
  const { locale } = useIntl()
  const { data, loading } = useMeQuery({ 
    variables: { 
      domain: window.location.hostname,
      locale
    }
  })

  const [currentRole, setCurrentRole] = useLocalStorageState<RoleType['id']>(ROLE_KEY)

  if (loading) return $(MessageContainer, null, 
    $(Spin, { size: 'xl' }))
  
  if (!data || !data.me) return $(MessageContainer, null,
    $(FormattedMessage, { id: 'nodata' }))
  
  const session = data.me
  
  localStorage.setItem(TOKEN_KEY, session.token) 
  
  if (!session.user) {
    const telegramBot = data?.telegramBot[0]
    return $(Routes, null,
      $(Route, { path: '*', element: $(Login, telegramBot) }))
  }

  const { roles } = session.user
  const role = roles.find(({ id }) => currentRole === id) || roles[0]

  if (!currentRole && role) setCurrentRole(role.id)

  if (!role) {
    setCurrentRole(undefined)

    return $(Unauthorized)
  }

  return $(ZiferblatContext.Provider, { 
    value: { 
      name: role.role.name, 
      ziferblatId: role.ziferblat?.id!
    }},
    $(AuthorizedRoutes))
}

const AuthorizedRoutes: FC = () =>
  $(Routes, null,
    $(Route, { 
      path: '*', 
      element: $(Main) 
    }))

export default App
