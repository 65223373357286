import { useLayoutContext } from "@gravity-ui/uikit"
import ZiferblatContext from "components/ZiferblatContext"
import { useTariffsQuery } from "queries"
import { createElement as $, FC, useContext } from "react"
import Desktop from "./Desktop"
import Mobile from "./Mobile"

const Tariff: FC = () => {
  const { activeMediaQuery } = useLayoutContext()
  const { ziferblatId } = useContext(ZiferblatContext)
  const props = useTariffsQuery({ variables: { ziferblatId }})
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  
  return $(component, props)
}

export default Tariff
